// css

import '../../styles/animation.css'

//

import { FC, useState } from 'react'

//

import { Col } from 'react-bootstrap'


//

interface MyButtonLinkPropsWA {

  linkTitle: string,
  style?: any

}

const MyButtonLink: FC<MyButtonLinkPropsWA> = ({ linkTitle, style}) => {

  const [color, setColor] = useState(true)


  return (

      <Col className='d-flex justify-content-center align-items-center animation_container' style={{borderRadius: '10px'}} onMouseEnter={() => setColor(false)} onMouseLeave={() => setColor(true)}>

        <div className='animation_box' style={{display: 'flex', justifyContent:'center', alignItems:'center', marginLeft: '20px'}}>

        <svg width="49" height="48" viewBox="0 0 49 48" xmlns="http://www.w3.org/2000/svg">
          <path d="M25.3838 48H23.6795C23.4267 47.9626 23.1865 47.9202 22.9387 47.8903C22.0183 47.783 21.0865 47.7319 20.1763 47.566C15.7514 46.7567 11.8321 44.9022 8.48691 41.9354C3.89891 37.8648 1.20857 32.8028 0.503112 26.7369C0.433578 26.1383 0.367831 25.5397 0.299561 24.9398V23.26C0.336224 23.0168 0.37668 22.7736 0.409551 22.5292C0.559998 21.423 0.632064 20.3006 0.871009 19.2144C2.33376 12.5536 6.01529 7.38682 11.8701 3.79266C17.118 0.570144 22.8578 -0.440009 28.9262 0.644972C34.5547 1.65139 39.2717 4.34264 42.9507 8.66635C47.8282 14.3968 49.5855 21.0202 48.3225 28.3806C47.5715 32.7542 45.6296 36.6115 42.622 39.9176C38.4904 44.4533 33.36 47.1021 27.2068 47.798L25.3838 48ZM24.562 12.0547C23.7503 12.1483 22.926 12.1794 22.1296 12.3466C14.4694 13.9204 10.3429 21.9879 13.5844 29.0191C13.6951 29.2714 13.729 29.55 13.6818 29.821C13.3265 31.633 12.9384 33.4376 12.5616 35.2447C12.4188 35.9331 12.6792 36.1825 13.372 36.0216C15.1774 35.6013 16.9802 35.1723 18.7906 34.7745C19.0456 34.7243 19.31 34.7513 19.5492 34.8518C21.8249 35.8308 24.1663 36.1463 26.6114 35.7161C29.7049 35.1832 32.4659 33.4812 34.3073 30.9719C36.1488 28.4626 36.9256 25.3439 36.4726 22.2785C35.6154 16.4583 30.5419 12.1271 24.562 12.0547Z" fill={(!color) ? 'white' : '#FA8129'}/>
          <path d="M15.8297 32.7018C16.0547 31.6367 16.2393 30.6652 16.4707 29.7087C16.5326 29.496 16.5507 29.2732 16.524 29.0534C16.4973 28.8337 16.4263 28.6214 16.3152 28.4292C15.5131 26.9756 15.1202 25.3367 15.1773 23.6827C15.262 19.5672 18.4429 15.7848 22.5467 14.9829C27.8667 13.9428 32.7379 17.1317 33.7354 22.4119C34.1755 24.7737 33.6569 27.2115 32.2916 29.1996C30.9263 31.1877 28.8236 32.5669 26.4368 33.0397C24.1902 33.4962 22.0549 33.1669 20.0321 32.1069C19.8703 32.0151 19.6913 31.9567 19.5059 31.9352C19.3205 31.9138 19.1326 31.9298 18.9537 31.9822C17.9536 32.2416 16.9397 32.4498 15.8297 32.7018ZM27.9666 29.8334C29.1638 29.9245 30.1019 29.1138 30.8403 27.9802C31.2499 27.3504 31.0248 26.7418 30.3017 26.5111C29.8238 26.359 29.3156 26.2842 28.8566 26.0909C27.9552 25.7167 27.2245 25.814 26.6214 26.6296C26.3686 26.9738 25.9665 27.0112 25.5974 26.8117C24.0815 25.9998 22.7721 24.8591 21.7679 23.4757C21.5416 23.1639 21.5644 22.8322 21.7907 22.5216C21.9373 22.3196 22.1447 22.1475 22.2395 21.9243C22.3482 21.6749 22.4797 21.3344 22.3963 21.1062C22.091 20.3081 21.7446 19.526 21.3583 18.7629C21.1358 18.3077 20.5997 18.1056 20.199 18.3688C19.2584 18.9923 18.5226 19.7855 18.4442 20.9877C18.3595 22.2897 18.8235 23.4333 19.5416 24.4796C20.7645 26.2655 22.3842 27.7528 24.2775 28.8282C25.3889 29.4888 26.6627 29.8361 27.9603 29.8322L27.9666 29.8334Z" fill={(!color) ? 'white' : '#FA8129'}/>
        </svg>

        <div className='circle_animation'></div>
        <div className='animation_text' style={{marginLeft: '10px', maxWidth: '260px', color: (!color) ? 'white' : 'black'}}>{linkTitle}</div>

        </div>
      </Col>

  )
}

export default MyButtonLink
